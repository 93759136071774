<script>
export default {
	metaInfo: {
		title: 'Blog',
	},
	pageView: true,
	data() {
		return {
			blogs: undefined,
			itemsPerPage: 10,
			offset: 0,
			modalActive: false,
			blogData: undefined,
		}
	},
	methods: {
		async fetchBlogItems(v = this.itemsPerPage, o = this.offset) {
			const apiUrl = `https://whiskyboutique.devdrubbit.com/wp-json/wp/v2/posts?per_page=${v}&offset=${o}`
			await fetch(apiUrl)
				.then((response) => response.json())
				.then((data) => (this.blogs = this.blogs ? [...this.blogs, ...data] : data))
		},
		activateModal(item, i) {
			this.blogData = {
				...item,
				index: i,
				prev: i === 0 ? null : this.blogs[i - 1].title.rendered,
				next: i === this.blogs.length - 1 ? null : this.blogs[i + 1].title.rendered,
			}
			this.modalActive = true
		},
		prevBlog() {
			let index = this.blogData.index
			this.activateModal(this.blogs[index - 1], index - 1)
		},
		nextBlog() {
			let index = this.blogData.index
			this.activateModal(this.blogs[index + 1], index + 1)
		},
	},
	async mounted() {
		await this.fetchBlogItems()
		this.$prerenderReady()
		const callback = (entries) => {
			const el = entries[0]
			if (el.isIntersecting) {
				this.itemsPerPage += 10
				this.offset += 10
				this.fetchBlogItems()
			}
		}
		const observer = new IntersectionObserver(callback, {})
		observer.observe(this.$refs.bottom)
	},
	beforeDestroy() {
		this.observer.disconnect()
	},
}
</script>
<template>
	<div>
		<Blog-Header class="mb-5" />
		<Container width="100%" max-width="1024px" style="min-height: 100vh">
			<v-row v-if="blogs" class="px-10">
				<v-col v-for="(blog, i) of blogs" :key="i" cols="12" sm="6" md="4">
					<v-card hover flat tile color="transparent" @click="activateModal(blog, i)">
						<Media height="400px" :src="blog.featured_image_url"> </Media>
						<div class="black--text text-uppercase font-weight-bold t4 pt-3 pb-2 border">
							<div style="height: 40px" class="line-clamp-2">
								{{ blog.title.rendered }}
							</div>
						</div>
						<div class="py-2 d-flex justify-end black--text text-uppercase font-weight-bold t4">
							Ver nota
						</div>
					</v-card>
				</v-col>
			</v-row>
		</Container>
		<div ref="bottom" class="py-5"></div>
		<BlogModal
			:data="blogData"
			v-if="modalActive"
			@close="modalActive = false"
			@prev="prevBlog"
			@next="nextBlog"
		/>
	</div>
</template>
